"use strict";
import { ethers } from 'ethers';

const Plugin = {
    install(Vue) {
        Vue.prototype.ethers = ethers;
        Object.defineProperties(Vue.prototype, {
            $ethers: {
                get() {
                    return ethers;
                }
            },
        })
    }
}

export default Plugin