import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const ifAuth = (to, from, next) => {
  localStorage.getItem('userToken') ? next() : next('/login')
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/Admins.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/user/:userId',
      name: 'user',
      component: () => import('@/views/User.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/tracks',
      name: 'tracks',
      component: () => import('@/views/TrackList.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/billboards',
      name: 'billboards',
      component: () => import('@/views/Billboards.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/cars',
      name: 'cars',
      component: () => import('@/views/Cars.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/notices',
      name: 'notices',
      component: () => import('@/views/Notices.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/version',
      name: 'version',
      component: () => import('@/views/Version.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/payrequest',
      name: 'payrequest',
      component: () => import('@/views/PayRequest.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/carModel/:brandId',
      name: 'carModel',
      component: () => import('@/views/CarModels.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/carPartTypes/:modelId',
      name: 'carPartTypes',
      component: () => import('@/views/CarPartTypes.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/carParts/:modelId/:typeEnum',
      name: 'carParts',
      component: () => import('@/views/CarParts.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/showroom',
      name: 'showroom',
      component: () => import('@/views/Showroom.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/liveMarketplace',
      name: 'liveMarketplace',
      component: () => import('@/views/Marketplace.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/races',
      name: 'races',
      component: () => import('@/views/Races.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/tournaments',
      name: 'tournaments',
      component: () => import('@/views/Tournaments.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/tournament/:tournamentsId',
      name: 'tournament',
      component: () => import('@/views/TournamentDetail.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      beforeEnter: ifAuth,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
  
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
