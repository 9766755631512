export const $themeColors = {}
export const $themeBreakpoints = {}

export const $themeConfig = {
  app: {
    appName: 'TheBend',
    appLogoImage: require('@/assets/images/logo/logo.svg'),
  },
  layout: {
    isRTL: false,
    skin: 'light',
    routerTransition: 'zoom-fade',
    type: 'horizontal',
    contentWidth: 'full',
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: 'sticky',
      backgroundColor: '',
    },
    footer: {
      type: 'hidden',
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
